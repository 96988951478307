import {
  signInWithCustomToken,
  UserCredential,
  IdTokenResult,
} from 'firebase/auth';
import { auth, functions } from './firebase';

import { httpsCallable } from 'firebase/functions';

const verifyToken = httpsCallable<{ token: string }, { jwt: string }>(
  functions,
  'verifyTokenCall',
);

export const signIn = async (
  token: string,
): Promise<[UserCredential, IdTokenResult]> => {
  const jwt = await getCustomToken(token);

  const credential = await signInWithCustomToken(auth, jwt);
  const tokenResult = await credential.user.getIdTokenResult();
  return [credential, tokenResult];
};

export const getCustomToken = async (token: string) => {
  const result = await verifyToken({ token });
  return result.data?.jwt ?? '';
};
