import React from 'react';

import { InviteView } from './InviteView';

import './App.css';

function App() {
  return <InviteView />;
}

export default App;
