import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

import config from './config.json';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: config.apiKey,
  authDomain: 'wedding-invite-a22fe.firebaseapp.com',
  projectId: 'wedding-invite-a22fe',
  storageBucket: 'wedding-invite-a22fe.appspot.com',
  messagingSenderId: '241790802918',
  appId: config.appId,
  measurementId: config.measurementId,
};

// Enable for debugging.
// @ts-ignore
// eslint-disable-next-line no-restricted-globals
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app, 'europe-west1');

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcdVUcqAAAAAEvWtvYQvmq4re_4PnGhxzpiferB'),
  isTokenAutoRefreshEnabled: true,
});

export { app, analytics, db, auth, functions, appCheck };
