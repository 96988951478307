import React, { FC, useEffect, useState } from 'react';
import { ParsedToken, IdTokenResult } from 'firebase/auth';

import { Tree } from './Tree';
import { signIn } from '../database/signIn';
import { InviteText } from './InviteText';
import { Form } from './Form';
import { getInviteByID, Invite, updateInviteByID } from '../database/invites';
import { Confirmation } from './Confirmation';
import { Splash } from './Splash';

type Claims = {
  name: string;
  partner: string | null;
  user_id: string;
} & ParsedToken;

type TokenResult = {
  uid: string;
} & IdTokenResult;

// TODO: obfuscate milou & sterre mobile numbers
// todo: add something like: je bent de hele dag uitgenodigd behavl het diner (not isNight). check the labels property

export const InviteView: FC = () => {
  // Token generated by the script and exchanged for a firebase JWT token to authenticate.
  const [persistentToken, setPersistentToken] = useState<string>('');

  // Firebase authentication token.
  const [tokenResult, setTokenResult] = useState<TokenResult | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const [invite, setInvite] = useState<Invite | null>(null);
  const [loading, setLoading] = useState(true);

  const [formPending, setFormPending] = useState(false);

  // Authenticate by token from URL.
  // Or if it's a reload authenticate from token in local storage.
  useEffect(() => {
    const token =
      new URLSearchParams(window.location.search).get('token') ||
      localStorage.getItem('token');

    if (!token) return;

    setPersistentToken(token);

    signIn(token)
      .then(([credential, tokenResult]) =>
        setTokenResult({ ...tokenResult, uid: credential.user.uid }),
      )
      .catch(setError);
  }, []);

  // Clean the URL and remove the given token.
  // Store token into local storage to support reload.
  useEffect(() => {
    if (persistentToken) {
      localStorage.setItem('token', persistentToken);
      window.history.replaceState({}, document.title, '/');
    }
  }, [persistentToken]);

  useEffect(() => {
    if (tokenResult?.uid) {
      getInviteByID(tokenResult.uid)
        .then(inv => {
          setInvite(inv);
          setLoading(false);
        })
        .catch(setError);
    }
  }, [tokenResult]);

  // Still authenticating and getting the invite from database.
  if (loading) {
    return <Splash />;
  }

  // Some error occurred while authenticating or getting invite from database.
  if (error !== null) {
    return <Splash cause={'error'} />;
  }

  // No data found.
  if (tokenResult === null || invite === null) {
    return <Splash cause={'nodata'} />;
  }

  const claims = tokenResult.claims as Claims;

  return (
    <div className="container" data-isnight={invite.isNight}>
      <div className={'block-container'}>
        <Tree isNight={invite.isNight} />
      </div>

      <div className={'block-container'}>
        <InviteText
          name={claims.name}
          isNight={invite.isNight}
          partner={claims.partner}
        />
      </div>

      <div className={'block-container-small'}>
        {invite?.email && invite.email !== '' ? (
          <Confirmation />
        ) : (
          <Form
            person={claims.name}
            partner={claims.partner}
            pending={formPending}
            onSubmit={values => {
              if (!tokenResult?.uid) return;

              setFormPending(true);

              updateInviteByID(tokenResult.uid, values)
                .then(() =>
                  setInvite((prevState: any) => {
                    if (prevState === null) return;
                    return { ...prevState, ...values };
                  }),
                )
                .catch(setError)
                .finally(() => setFormPending(false));
            }}
          />
        )}
      </div>
    </div>
  );
};
