import React, { FC } from 'react';

export const Splash: FC<{ cause?: 'error' | 'nodata' }> = ({ cause }) => {
  return (
    <div className="suspense-container">
      <div style={{ height: '100vh' }} className={'block-container'}>
        {cause && cause === 'error' && renderError()}
        {cause && cause === 'nodata' && renderNoData()}

        <div className={'item-container'}>{renderEnvelope()}</div>

        <div className={'empty'} />
      </div>
    </div>
  );
};

const renderError = () => (
  <div className={'item-container'}>
    <h2 className={'error-message'}>
      Er is helaas iets mis gegaan, probeer de pagina te verversen, of neem
      contact met ons op.
    </h2>
  </div>
);

const renderNoData = () => (
  <div className={'item-container'}>
    <h2 className={'error-message'}>
      Er is geen uitnodiging gevonden, gebruik de link die wij naar je toe
      hebben gestuurd, of neem contact met ons op.
    </h2>
  </div>
);

const renderEnvelope = () => (
  <div>
    <svg viewBox="141.88 104.432 234.235 222.98" width="200" height="200">
      <polygon
        points="144.073 199.026 259.107 104.432 374.138 199.026"
        fill="#FFCC66"
        stroke="#E5A150"
        strokeWidth="2"
        // @ts-ignore
        style={{ transformOrigin: '259.105px 151.729px' }}
      />
      <rect
        id="letter"
        x="187.496"
        y="210.571"
        width="141.664"
        height="78.024"
        fill="white"
        stroke="#ccc"
        strokeWidth="2"
        transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
      >
        <animateMotion
          path="M 0 0 L -1.263 -108.541"
          calcMode="linear"
          dur="0.5s"
          fill="freeze"
        />
        <animateMotion
          path="M -1.405 -107.097 L 0.629 -1.053"
          calcMode="linear"
          begin="2.1s"
          dur="0.5s"
          fill="freeze"
        />
      </rect>
      <path
        id="heart"
        fill="#E74C3C"
        d="M 257.347 268.587 L 256.747 268.06 C 243.847 256.993 240.347 253.093 240.347 246.769 C 240.347 241.498 244.347 237.282 249.347 237.282 C 253.447 237.282 255.747 239.707 257.347 241.604 C 258.947 239.707 261.247 237.282 265.347 237.282 C 270.347 237.282 274.347 241.498 274.347 246.769 C 274.347 253.093 270.847 256.993 257.947 268.06 L 257.347 268.587 Z M 249.347 239.39 C 245.447 239.39 242.347 242.658 242.347 246.769 C 242.347 252.144 245.547 255.728 257.347 265.847 C 269.147 255.728 272.347 252.144 272.347 246.769 C 272.347 242.658 269.247 239.39 265.347 239.39 C 261.847 239.39 259.947 241.604 258.447 243.396 L 257.347 244.766 L 256.247 243.396 C 254.747 241.604 252.847 239.39 249.347 239.39 Z"
        style={{
          paintOrder: 'fill',
          transformOrigin: '257.347px 252.935px',
        }}
      >
        <animateMotion
          path="M 0 0 L -1.263 -108.541"
          calcMode="linear"
          dur="0.5s"
          fill="freeze"
        />
        <animateTransform
          type="scale"
          additive="sum"
          attributeName="transform"
          values="1 1;2 2"
          begin="0.5s"
          dur="0.8s"
          keyTimes="0; 1"
          fill="freeze"
          calcMode="spline"
          keySplines="0.65 0 0.35 1"
        />
        <animateTransform
          type="scale"
          additive="sum"
          attributeName="transform"
          values="1 1;0.5 0.5"
          begin="1.3s"
          dur="0.8s"
          fill="freeze"
          keyTimes="0; 1"
          calcMode="spline"
          keySplines="0.65 0 0.35 1"
        />
        <animateMotion
          path="M -0.702 -108.15 L 2.034 -3.863"
          calcMode="linear"
          begin="2.1s"
          dur="0.5s"
          fill="freeze"
        />
      </path>
      <rect
        x="142.355"
        y="200.952"
        width="233.53"
        height="126.264"
        fill="#FFCC66"
        stroke="#E5A150"
        strokeWidth="2"
        transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
      />
      <polygon
        points="141.88 327.412 258.997 248.497 376.115 327.412"
        fill="#E5A150"
        opacity="0.7"
        transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
      />
    </svg>
  </div>
);
