import React, { FC } from 'react';

export const InviteText: FC<{
  name: string;
  partner?: string | null;
  isNight: boolean;
}> = ({ name, partner, isNight }) => {
  return (
    <div className="item-container">
      <p className={'card card-content'}>
        Lieve {name}
        {partner ? ` & ${partner},` : ','}
        <br />
        <br />
        {isNight ? renderNightText(!!partner) : renderDayText(!!partner)}
      </p>
    </div>
  );
};

const renderNightText = (partner: boolean) => (
  <>
    Wij kunnen niet wachten om elkaar het jawoord te geven en vinden het fijn
    als {partner ? 'jullie' : 'jij'} erbij {partner ? 'zijn' : 'bent'} met deze
    speciale avond. <br />
    <br />
    Zou je willen laten weten, via de onderstaande RSVP, of{' '}
    {partner ? 'jullie' : 'je'} er deze dag bij {partner ? 'kunnen' : 'kunt'}{' '}
    zijn om met ons de liefde te vieren?
    {renderFooter(true)}
  </>
);

const renderDayText = (partner: boolean) => (
  <>
    Wij kunnen niet wachten om elkaar het jawoord te geven en vinden het fijn
    als {partner ? 'jullie' : 'jij'} erbij {partner ? 'zijn' : 'bent'} op deze
    speciale dag. <br /> <br />
    Graag nodigen we {partner ? 'jullie' : 'jou'} uit op 15 mei 2025 voor de
    ceremonie en het feest. {partner ? 'Zouden jullie' : 'Zou je'} willen laten
    weten, via de onderstaande RSVP, of {partner ? 'jullie' : 'je'} er deze dag
    bij {partner ? 'kunnen' : 'kunt'} zijn om met ons de liefde te vieren?
    {renderFooter(false)}
  </>
);

const renderFooter = (isNight: boolean) => (
  <>
    <br />
    <br />
    <b>Locatie:</b> Castricum <br />
    <b>Tijd:</b> {isNight ? '20:30' : '14:30'}
    <br />
    <br />
    Verdere informatie volgt in de officiële uitnodiging.
    <br />
    <br /> <b>Ceremoniemeesters</b>
    <br /> Sterre (0611095013) & Milou (0658939739)
  </>
);
